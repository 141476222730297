import React from 'react';
import { CssBaseline } from '@material-ui/core';
import {
	ThemeProvider as MuiThemeProvider,
	StylesProvider,
} from '@material-ui/styles';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as ScThemeProvider } from 'styled-components';

import { useConfig } from '../config/ConfigContext';
import createTheme from './theme';

const Styling: React.FC = ({ children }) => {
	const frontendConfig = useConfig('frontendConfig');
	const disableMenus = useConfig('disableMenus');

	if (frontendConfig === undefined) {
		// Still loading theme config from backend
		return null;
	}

	const theme = createTheme(
		frontendConfig.theme.baseColor,
		disableMenus ? '0' : '300px',
	);

	return (
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={theme}>
				<ScThemeProvider theme={theme}>
					<CssBaseline />
					{children}
				</ScThemeProvider>
			</MuiThemeProvider>
		</StylesProvider>
	);
};

export default Styling;
