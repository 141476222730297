import { buildRequestUrl } from '../hooks/fetchFunctions';

export type SagaService =
	| 'acquire'
	| 'event'
	| 'extension'
	| 'frontend-support'
	| 'monitoring'
	| 'settings'
	| 'statistics'
	| 'subscription'
	| 'vocabulary';

const apiUrl = (apiBase: string) => <S extends SagaService>(
	service: S,
	endpoint: string,
) => new URL(buildRequestUrl(apiBase, service, endpoint));

export { apiUrl };
