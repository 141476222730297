import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import { Loading } from '@persequor-com/common';
import { SnackbarProvider } from 'notistack';

import { ApiProvider } from './config/ApiContext';
import { ConfigProvider } from './config/ConfigContext';
import { AuthProvider } from './auth/AuthContext';
import Styling from './style/Styling';
import ErrorBoundary from './ErrorBoundary';
import Auth from './auth/Auth';
import App from './App';
import useInternationalisation from './i18n/useInternationalisation';

const Root = () => {
	const { loadingTranslations } = useInternationalisation();

	if (loadingTranslations) {
		return <Loading />;
	}

	return (
		<ConfigProvider>
			<Styling>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<ErrorBoundary>
						<AuthProvider>
							<ApiProvider>
								<Auth>
									<App />
								</Auth>
							</ApiProvider>
						</AuthProvider>
					</ErrorBoundary>
				</SnackbarProvider>
			</Styling>
		</ConfigProvider>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));
