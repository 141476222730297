import React, { createContext, useContext } from 'react';
import { flatten, pipe, pluck, prop, uniqBy } from 'ramda';

import { useComponents } from './ComponentsLoader';
import { useExtensions } from './ExtensionsLoader';

type ComponentSchema = import('./types/ComponentSchema').ComponentSchema;
type RouteSchema = import('./types/ComponentSchema').RouteSchema;
type RoutesProviderProps = { children: React.ReactNode };
type RoutesContextValue = {
	routes: RouteSchema[];
	loading: boolean;
};

const RoutesContext = createContext<RoutesContextValue | undefined>(undefined);

const extractRoutes: (i: ComponentSchema[]) => RouteSchema[] = pipe(
	pluck('routes'),
	flatten,
	uniqBy(prop('id')),
);

const RoutesProvider = ({ children }: RoutesProviderProps) => {
	const { components, loading: componentsLoading } = useComponents();
	const { extensions, loading: extensionsLoading } = useExtensions();

	const routes: RouteSchema[] = extractRoutes([...extensions, ...components]);
	const loading = componentsLoading || extensionsLoading;

	return (
		<RoutesContext.Provider value={{ routes, loading }}>
			{children}
		</RoutesContext.Provider>
	);
};

const useRoutesState = () => {
	const context = useContext(RoutesContext);

	if (context === undefined) {
		throw new Error('useRoutesState must be used within a RoutesProvider');
	}
	return context;
};

export { RoutesProvider, useRoutesState };
