import React, { useState, createContext, useContext } from 'react';

type MenuProviderProps = {
	children: React.ReactNode;
};

type MenuState = {
	open: boolean;
	setOpen: (isOpen: boolean) => void;
};

const MenuStateContext = createContext<MenuState | undefined>(undefined);

const MenuProvider = ({ children }: MenuProviderProps) => {
	const [open, setOpen] = useState(true);

	return (
		<MenuStateContext.Provider value={{ open, setOpen }}>
			{children}
		</MenuStateContext.Provider>
	);
};

const useMenu = () => {
	const context = useContext(MenuStateContext);

	if (context === undefined) {
		throw new Error('useMenu must be used within a MenuProvider');
	}

	return context;
};

export { useMenu, MenuProvider };
