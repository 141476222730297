import React, { createContext, useContext, useEffect, useState } from 'react';

import { getComponent } from './utils/componentUtils';

type ComponentSchema = import('./types/ComponentSchema').ComponentSchema;

type ComponentsContextValue = {
	components: ComponentSchema[];
	loading: boolean;
};

type ComponentsProviderProps = { children: React.ReactNode };

const ComponentsContext = createContext<ComponentsContextValue>({
	components: [],
	loading: true,
});

const ComponentsProvider = ({ children }: ComponentsProviderProps) => {
	const [components, setComponents] = useState<ComponentSchema[]>([]);
	const [enabledComponents, setEnabledComponents] = useState<
		string[] | undefined
	>(undefined);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getComponentsConfig = async () => {
			try {
				const componentsConfig = await import(
					/* webpackChunkName: "config/components" */
					/* webpackPreload: true */
					`./config/components`
				);
				setEnabledComponents(componentsConfig.enabled);
			} catch (err) {
				console.error('Components config not found', err);
				setLoading(false);
			}
		};

		getComponentsConfig();
	}, []);

	useEffect(() => {
		const getEnabledComponents = async () => {
			if (Array.isArray(enabledComponents)) {
				const components = await Promise.all(
					enabledComponents.map((c) => getComponent(c)),
				);
				setComponents(components);
				setLoading(false);
			}
		};

		getEnabledComponents();
	}, [enabledComponents]);

	return (
		<ComponentsContext.Provider value={{ components, loading }}>
			{children}
		</ComponentsContext.Provider>
	);
};

const useComponents = () => {
	const context = useContext(ComponentsContext);

	if (context === undefined) {
		throw new Error(
			'useComponents must be used within a ComponentsProvider',
		);
	}
	return context;
};

export { ComponentsProvider, useComponents };
