import React, { Suspense, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import {
	Error,
	NoData,
	Loading,
	DelayedRender,
	CopyButton,
	t,
} from '@persequor-com/common';
import { Button, ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useComponents } from '../../ComponentsLoader';
import { useExtensions } from '../../ExtensionsLoader';
import {
	getVocabularyDetailsExtensions,
	getButtons,
	getSections,
} from './vocabularyDetailsFunctions';
import { prepareComponentPath } from '../../utils/componentUtils';

type VocabularyContentProps = {
	elements: import('@persequor-com/common').VocabularyElement[];
	error?: import('@persequor-com/common').SagaError;
	hideButtons?: boolean;
	idLabel?: string;
	itemId: string;
	loading: boolean;
	vocabularyName: string;
	forceUpdate?: () => void;
};

const Padding = styled.div`
	padding: 16px;
`;

const ChildPadding = styled.div`
	padding-bottom: 1em;
	text-align: center;
`;

const ItemId = styled.div`
	padding-bottom: 1em;
`;

const VocabularyContent = ({
	elements,
	error,
	hideButtons,
	idLabel = t`ID`,
	itemId,
	loading,
	vocabularyName,
	forceUpdate,
}: VocabularyContentProps) => {
	const { components } = useComponents();
	const { extensions } = useExtensions();

	const { buttons, sections } = useMemo(() => {
		const vocabularyDetailsExtensions = getVocabularyDetailsExtensions(
			[...components, ...extensions],
			vocabularyName,
		);

		const buttons = getButtons(vocabularyDetailsExtensions);
		const sections = getSections(vocabularyDetailsExtensions);

		return { buttons, sections };
	}, [components, extensions, vocabularyName]);

	const hasSections = sections.length > 0;
	const hasElements = elements.length > 0;

	useEffect(() => {
		forceUpdate?.();
	}, [loading, forceUpdate]);

	return (
		<Padding>
			{!hideButtons && buttons.length > 0 && (
				<ChildPadding>
					<ButtonGroup>
						{buttons.map((button) => {
							const path = prepareComponentPath(button.path);

							return (
								<Button
									key={
										button.id ??
										`${button.label}${button.order}`
									}
									component={Link}
									to={path(itemId)}
									data-testid={button.id}
								>
									{button.label}
								</Button>
							);
						})}
					</ButtonGroup>
				</ChildPadding>
			)}

			{error ? (
				<Error>{error}</Error>
			) : (hasSections && hasElements) || loading ? (
				<Suspense fallback={<Loading delay={100} />}>
					<ItemId>
						<strong>{idLabel}</strong>
						<CopyButton
							copyLabel={t`Click to copy ${idLabel}`}
							copiedLabel={t`${idLabel} copied!`}
							data={itemId}
						/>
						<br />
						{itemId}
					</ItemId>
					{sections.map((section) => (
						<section.component
							key={
								section.id ?? `${section.type}${section.order}`
							}
							itemId={itemId}
							elements={elements}
						/>
					))}
				</Suspense>
			) : (
				<DelayedRender>
					<NoData noContainer />
				</DelayedRender>
			)}
		</Padding>
	);
};

export default VocabularyContent;
