import React from 'react';
import {
	Link,
	Popover,
	extractVocabularyElements,
} from '@persequor-com/common';

import VocabularyDetailsContent from './VocabularyDetailsContent';
import VocabularyIdentifier from './VocabularyIdentifier';
import { useVocabularyApi } from '../../hooks/useSaga';

export type VocabularyDetailsAsyncProps = {
	displayName?: string;
	hideButtons?: boolean;
	itemId?: string;
	idLabel?: string;
	placement?: import('@material-ui/core').PopperPlacementType;
	useId?: boolean;
	vocabularyId?: string;
	vocabularyName?: string;
};

type VocabularyContentAsyncProps = {
	hideButtons?: boolean;
	isOpen: boolean;
	itemId: string;
	idLabel?: string;
	vocabularyId?: string;
	vocabularyName: string;
	forceUpdate: () => void;
};

type Vocabulary = import('@persequor-com/common').Vocabulary;

const VocabularyDetailsAsync = ({
	displayName,
	hideButtons,
	itemId,
	idLabel,
	placement,
	useId,
	vocabularyId,
	vocabularyName,
}: VocabularyDetailsAsyncProps) => {
	if (itemId && vocabularyName) {
		return (
			<Popover
				id={`vocabularyPopover-${itemId}`}
				placement={placement}
				anchor={
					<Link>
						<VocabularyIdentifier
							icon="link"
							id={itemId}
							displayName={displayName}
							useId={useId}
						/>
					</Link>
				}
				render={(isOpen, forceUpdate) =>
					vocabularyName ? (
						<VocabularyContentAsync
							hideButtons={hideButtons}
							isOpen={isOpen}
							itemId={itemId}
							idLabel={idLabel}
							vocabularyId={vocabularyId}
							vocabularyName={vocabularyName}
							forceUpdate={forceUpdate}
						/>
					) : null
				}
			/>
		);
	} else if (itemId) {
		return (
			<VocabularyIdentifier
				id={itemId}
				displayName={displayName}
				useId={useId}
			/>
		);
	} else {
		return null;
	}
};

const VocabularyContentAsync = ({
	hideButtons,
	isOpen,
	itemId,
	idLabel,
	vocabularyId,
	vocabularyName,
	forceUpdate,
}: VocabularyContentAsyncProps) => {
	const [response, loading, error] = useVocabularyApi<Vocabulary>({
		endpoint: '/epcis/vocabularies',
		ready: isOpen && !!vocabularyId,
		// eslint-disable-next-line @typescript-eslint/camelcase
		params: { vocabularyName, EQ_name: vocabularyId },
	});
	const elements = extractVocabularyElements(response);

	return (
		<VocabularyDetailsContent
			elements={elements}
			error={error}
			hideButtons={hideButtons}
			itemId={itemId}
			idLabel={idLabel}
			loading={loading}
			vocabularyName={vocabularyName}
			forceUpdate={forceUpdate}
		/>
	);
};

export default VocabularyDetailsAsync;
