import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

type MenuItemProps = {
	label: string;
	to: string;
};

const MenuItem = ({ label, to }: MenuItemProps) => {
	return (
		<ListItem
			button
			key={label}
			component={NavLink}
			to={to ?? ''}
			activeClassName="Mui-selected"
		>
			<ListItemText>{label}</ListItemText>
		</ListItem>
	);
};

export default MenuItem;
