import React from 'react';
import { isEmpty } from 'ramda';

import { SectionGrid } from './Section';
import Row from './Row';
import CoreValue from '../value-items/CoreValue';
import ExtensionValue from '../value-items/ExtensionValue';

type EventDetailsSectionRow = import('../../types/ComponentSchema').EventDetailsSectionRow;
type EventExtensionSectionRow = import('@persequor-com/common').EventExtensionSectionRow;

type SectionRowsProps = {
	loading: boolean;
	eventValue: <T extends unknown>(path: string) => T | undefined;
	rows: (EventDetailsSectionRow | EventExtensionSectionRow)[];
	useId: boolean;
};

const SectionRows = ({
	eventValue,
	loading,
	rows,
	useId,
}: SectionRowsProps) => (
	<SectionGrid>
		{rows.map((row) => {
			// Pre-check value so row isn't rendered if empty
			const value = 'value' in row && eventValue(row.value);

			return (
				<Row key={row.header} header={row.header} loading={loading}>
					{'data' in row ? (
						<ExtensionValue {...row.data} />
					) : value && !isEmpty(value) ? (
						<CoreValue
							data={row}
							eventValue={eventValue}
							useId={useId}
						/>
					) : null}
				</Row>
			);
		})}
	</SectionGrid>
);

export default SectionRows;
