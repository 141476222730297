import React, { Suspense, useMemo, useCallback } from 'react';
import {
	Switch,
	Typography,
	FormControlLabel,
	Drawer,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { dates, dotPath, t, useToggle } from '@persequor-com/common';
import styled from 'styled-components/macro';

import { useComponents } from '../../ComponentsLoader';
import { useExtensions } from '../../ExtensionsLoader';
import { getEventDetailsExtensions } from './eventDetailsFunctions';
import Section from './Section';
import SectionRows from './SectionRows';
import SkeletonRows from './SkeletonRows';

type WrappedEvent = import('@persequor-com/common').WrappedEvent;

type EventDetailsProps = {
	hideEvent: () => void;
	loading: boolean;
	open: boolean;
	wrappedEvent?: WrappedEvent;
};

const Padding = styled.div`
	padding: 20px;
	min-width: 50vw;
	max-width: 50vw;
`;

const EventDetails = ({
	hideEvent,
	loading,
	open,
	wrappedEvent,
}: EventDetailsProps) => {
	const { components } = useComponents();
	const { extensions } = useExtensions();
	const [useId, toggleId] = useToggle(false);
	const eventDetailsSections = useMemo(
		() =>
			[
				...getEventDetailsExtensions([...components, ...extensions]),
				...(wrappedEvent?.extension ?? []),
			].sort((a, b) => a.order - b.order),
		[components, extensions, wrappedEvent],
	);

	const eventValue = useCallback(
		<T extends unknown>(path?: string): T | undefined =>
			path ? dotPath(path, wrappedEvent) : undefined,
		[wrappedEvent],
	);

	const reverted = wrappedEvent?.revertErrorDeclaration;
	const revert = wrappedEvent?.event.errorDeclaration;
	const revertDate = reverted && dates.format(reverted.declarationTime);
	const revertReason =
		reverted?.reason && t` with reason: ${reverted.reason}`;

	return (
		<Drawer anchor="right" open={open} onClose={hideEvent}>
			<Padding>
				<Typography variant="h5">{t`Event Details`}</Typography>

				<FormControlLabel
					control={
						<Switch
							onChange={toggleId}
							checked={useId}
							data-testid="useid"
						/>
					}
					label={t`Show ID`}
				/>

				{!loading && !wrappedEvent && (
					<Alert severity="error">{t`No event details found`}</Alert>
				)}

				{!loading && reverted && (
					<Alert
						severity="warning"
						data-testid="alert-reverted-event"
					>
						<AlertTitle>{t`Reverted event`}</AlertTitle>
						<p>{t`This event was reverted on ${revertDate}${revertReason}.`}</p>
						<p>{t`See additional information in the Error Declaration section below.`}</p>
					</Alert>
				)}

				{!loading && revert && (
					<Alert severity="info">
						<AlertTitle>{t`Revert event`}</AlertTitle>
						<p>{t`This is a revert event, see additional information in the Error Declaration section below`}</p>
					</Alert>
				)}

				{eventDetailsSections.map((section) => {
					if (
						'requireDataAt' in section &&
						!eventValue(section.requireDataAt)
					) {
						return null;
					}

					return (
						<Section
							key={`${section.order}-${section.header}`}
							header={section.header}
							visibility={section.visibility}
						>
							{'rows' in section ? (
								<SectionRows
									loading={loading}
									eventValue={eventValue}
									rows={section.rows}
									useId={useId}
								/>
							) : (
								<Suspense fallback={<SkeletonRows />}>
									<section.component
										loading={loading}
										useId={useId}
										wrappedEvent={wrappedEvent}
									/>
								</Suspense>
							)}
						</Section>
					);
				})}
			</Padding>
		</Drawer>
	);
};

export default EventDetails;
