import React from 'react';
import { List } from '@material-ui/core';
import { getPrettyVersion, Icon, t } from '@persequor-com/common';
import styled from 'styled-components/macro';

import {
	menusInSection,
	getMenusWithoutSection,
	prepareSections,
	getMenus,
} from './menuFunctions';
import MenuItem from './MenuItem';
import MenuSection from './MenuSection';
import { useComponents } from '../ComponentsLoader';
import { useExtensions } from '../ExtensionsLoader';
import { useConfig } from '../config/ConfigContext';

const MenuFooter = styled.small`
	padding: 0.3em;
	display: block;
	color: #586069;
	text-align: center;
	margin-top: 3em;
`;

const MenuContent = () => {
	const menuSections = useConfig('menuSections');
	const loginConfiguration = useConfig('loginConfiguration');
	const { components } = useComponents();
	const { extensions } = useExtensions();

	const menu = getMenus([...extensions, ...components], loginConfiguration);
	const sections = prepareSections(menuSections ?? []);
	const menusWithoutSection = getMenusWithoutSection(menu, sections);
	const year = process.env.REACT_APP_VERSION?.substring(0, 4);

	return (
		<>
			<List disablePadding>
				{sections.map(({ id, label, icon }) => {
					const sectionMenus = menusInSection(menu, id);

					return (
						sectionMenus.length > 0 && (
							<MenuSection
								key={id}
								label={label}
								icon={icon.name}
							>
								{sectionMenus.map(({ label, to }) => (
									<MenuItem key={to} label={label} to={to} />
								))}
							</MenuSection>
						)
					);
				})}

				{menusWithoutSection.length > 0 && (
					<MenuSection
						label={t`Unconfigured section`}
						icon="question-circle"
					>
						{menusWithoutSection.map(({ label, to }) => (
							<MenuItem key={to} label={label} to={to} />
						))}
					</MenuSection>
				)}
			</List>

			<MenuFooter>
				<div>
					<Icon name="copyright" size="sm" />
					{`${year} Persequor ApS`}
				</div>
				<div>
					{getPrettyVersion(process.env.REACT_APP_VERSION ?? '')}
				</div>
			</MenuFooter>
		</>
	);
};

export default MenuContent;
