import React from 'react';
import { Hidden } from '@material-ui/core';

import MenuContent from './MenuContent';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import useRouteChange from './useRouteChange';
import useDesktop from './useDesktop';
import { useMenu } from './MenuContext';
import { useConfig } from '../config/ConfigContext';

const Menu = () => {
	const { open, setOpen } = useMenu();
	const disableMenus = useConfig('disableMenus');

	if (disableMenus) return null;

	return (
		<nav>
			<Hidden mdUp>
				<MobileMenu open={open} handleClose={setOpen}>
					<MenuContent />
				</MobileMenu>
			</Hidden>

			<Hidden smDown>
				<DesktopMenu>
					<MenuContent />
				</DesktopMenu>
			</Hidden>

			<MenuAutoClose setOpen={setOpen} />
		</nav>
	);
};

/**
 * Avoid strange issue with Material UI resetting the scroll position of the
 * List in MenuContent anytime a re-render happens, which was triggered by
 * useLocation when navigating to a new page. By separating to its own
 * component, it no longer affect that part of the tree when it shouldn't.
 */
const MenuAutoClose = ({ setOpen }: { setOpen: Function }) => {
	// Close mobile menu on route change
	useRouteChange(setOpen, false);

	// Close mobile menu when resized to desktop size
	useDesktop(setOpen, false);

	return null;
};

export default Menu;
