import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import Top from './Top';
import Menu from './menu/Menu';
import { MenuProvider } from './menu/MenuContext';
import Main from './Main';
import { ComponentsProvider } from './ComponentsLoader';
import { ExtensionsProvider } from './ExtensionsLoader';
import { RoutesProvider } from './RoutesContext';

const AppRoot = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`;

const App = () => {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL ?? '/'}>
			<MuiPickersUtilsProvider utils={LuxonUtils}>
				<ComponentsProvider>
					<ExtensionsProvider>
						<RoutesProvider>
							<AppRoot>
								<MenuProvider>
									<Top />
									<Menu />
								</MenuProvider>
								<Main />
							</AppRoot>
						</RoutesProvider>
					</ExtensionsProvider>
				</ComponentsProvider>
			</MuiPickersUtilsProvider>
		</BrowserRouter>
	);
};

export default App;
