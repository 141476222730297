import {
	differenceWith,
	filter,
	pipe,
	prop,
	propEq,
	sortBy,
	uniqBy,
} from 'ramda';
import { dedupeByProperty } from '@persequor-com/common';

type ComponentSchema = import('../types/ComponentSchema').ComponentSchema;
type MenuSchema = import('../types/ComponentSchema').MenuSchema;
type Section = import('../types/MenuSchema').Section;

/**
 * Filter array of objects by section prop.
 * Example: filterBySection(menu)(id)
 * @param {Array} menus - An array to filter
 * @param {string} id - An id to filter by
 */
const filterBySection = (menus: MenuSchema[], id: string): MenuSchema[] =>
	filter(propEq('section', id), menus);

/**
 * Extracts all menus belonging to a section, and orders them.
 * @param {Array} menus - All menu items
 * @param {string} id - Section id
 * @returns {Array} Sorted menu items
 */
const menusInSection: {
	(menus: MenuSchema[], id: string): MenuSchema[];
} = pipe(filterBySection, sortBy(prop('order')));

/**
 * Extracts all menus belonging to a section which isn't configured.
 * @param {Array} menu - All menu items
 * @param {Array} sections - All sections
 * @returns {Array} Menu items with unconfigured section
 */
const getMenusWithoutSection = differenceWith(
	(m: MenuSchema, s: Section) => m.section === s.id,
);

/**
 * Makes sure sections are unique and sorted.
 * @param {Array} sections - All sections
 * @returns {Array} Sorted and unique sections
 */
const prepareSections: { (sections: Section[]): Section[] } = pipe(
	sortBy(prop('order')),
	uniqBy(prop('id')),
);

/**
 * Extract menu definitions from components and extensions. Ensures menu items
 * are unique so that an extension can override a core component menu.
 * @param {Array} components - Components and extensions
 * @returns {Array} Unique menu items
 */
const getMenus = (
	components: ComponentSchema[],
	hideCriteria = '',
): MenuSchema[] => {
	const menus = components
		.flatMap((component) => component.menus)
		.filter((menu) => menu.hide !== hideCriteria);

	return dedupeByProperty(menus, 'id');
};

export { menusInSection, getMenusWithoutSection, prepareSections, getMenus };
