import { apiUrl } from '../config/configFunctions';

export type Credentials = { username: string; password: string };

export const logout = async (base: string) => {
	let redirectURL;

	try {
		const response = await fetch(
			apiUrl(base)('frontend-support', '/signout').href,
			{
				method: 'POST',
				credentials: 'include',
				headers: { 'X-Requested-With': 'XMLHttpRequest' },
			},
		);
		const json = await response.json();
		redirectURL = json.redirectAfterLogoutURL;
	} catch (e) {
		console.error(e);
	}

	window.location.href = redirectURL ? redirectURL : '/';
};

export const createSession = async (base: string, credentials: Credentials) => {
	return fetch(apiUrl(base)('frontend-support', '/login').href, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(credentials),
		headers: {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
		},
	})
		.then((response) => response.ok)
		.catch(function (message) {
			console.error(message);
			return false;
		});
};
