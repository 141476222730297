import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Icon, t } from '@persequor-com/common';

import { useAuthActions } from '../auth/AuthContext';

const UserMenu = () => {
	const { logout } = useAuthActions();
	const [anchorEl, setAnchorEl] = useState<Element>();
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<>
			<IconButton onClick={handleMenu} color="inherit">
				<Icon name="user" />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					disabled
					onClick={handleClose}
				>{t`Preferences`}</MenuItem>
				<MenuItem
					disabled
					onClick={handleClose}
				>{t`Change password`}</MenuItem>
				<MenuItem onClick={logout}>{t`Log out`}</MenuItem>
			</Menu>
		</>
	);
};

export default UserMenu;
