const addLeadingSlash = (path: string) =>
	path.charAt(0) === '/' ? path : '/' + path;

const buildRequestUrl = (
	apiBase = '',
	service: string,
	endpoint = '',
	params?: Record<string, string>,
) => {
	const url = new URL(
		`${apiBase}/${service}${addLeadingSlash(endpoint)}`,
		window.location.origin,
	);
	url.search = new URLSearchParams(params).toString();

	return url.href;
};

export { buildRequestUrl };
