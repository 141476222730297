import React from 'react';
import { Icon } from '@persequor-com/common';
import styled from 'styled-components/macro';

type EventExtensionDataIcon = import('@persequor-com/common').EventExtensionDataIcon;

type WithIconProps = {
	icon: EventExtensionDataIcon;
	children?: string;
};

const StyledIcon = styled.span`
	white-space: nowrap;
`;

const WithIcon = ({ icon, children }: WithIconProps) => {
	const iconName = icon?.name ?? 'exclamation-circle';

	return (
		<StyledIcon>
			{icon?.placement === 'before' && <>{children} </>}
			<Icon {...icon} name={iconName} />
			{icon?.placement === 'after' && <> {children}</>}
		</StyledIcon>
	);
};

export default WithIcon;
