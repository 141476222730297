import { useFrontendSupportApi } from '../hooks/useSaga';

export const useSessionState = () => {
	const [
		sessionValid,
		checkingSession,
		sessionError,
		checkSession,
	] = useFrontendSupportApi<boolean>({
		endpoint: '/session',
		options: { method: 'HEAD' },
		ready: true,
		responseParser: async (res) => res?.status === 200,
	});

	return { sessionValid, checkingSession, sessionError, checkSession };
};
