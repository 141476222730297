import React, { lazy } from 'react';

type RenderType = import('./Builder').RenderType;

export type BuildingBlocks =
	| import('./EventsTableBlock').EventsTableBlock
	| import('./FormBlock').FormBlock
	| import('./GeoMapBlock').GeoMapBlock
	| import('./GraphBlock').GraphBlock
	| import('./GroupingBlock').GroupingBlock
	| import('./ImageBlock').ImageBlock
	| import('./PieChartBlock').PieChartBlock
	| import('./RedirectBlock').RedirectBlock
	| import('./RowBlock').RowBlock
	| import('./TableBlock').TableBlock
	| import('./TextBlock').TextBlock
	| import('./TimelineBlock').TimelineBlock
	| import('./ToastBlock').ToastBlock;

const FormBlock = lazy(() =>
	import(/* webpackChunkName: "FormBlock" */ './FormBlock'),
);
const EventsTableBlock = lazy(() =>
	import(/* webpackChunkName: "EventsTableBlock" */ './EventsTableBlock'),
);
const GraphBlock = lazy(() =>
	import(/* webpackChunkName: "GraphBlock" */ './GraphBlock'),
);
const PieChartBlock = lazy(() =>
	import(/* webpackChunkName: "PieChartBlock" */ './PieChartBlock'),
);
const TableBlock = lazy(() =>
	import(/* webpackChunkName: "TableBlock" */ './TableBlock'),
);
const TextBlock = lazy(() =>
	import(/* webpackChunkName: "TextBlock" */ './TextBlock'),
);
const RedirectBlock = lazy(() =>
	import(/* webpackChunkName: "RedirectBlock" */ './RedirectBlock'),
);
const GeoMapBlock = lazy(() =>
	import(/* webpackChunkName: "GeoMapBlock" */ './GeoMapBlock'),
);
const GroupingBlock = lazy(() =>
	import(/* webpackChunkName: "GroupingBlock" */ './GroupingBlock'),
);
const ImageBlock = lazy(() =>
	import(/* webpackChunkName: "ImageBlock" */ './ImageBlock'),
);
const TimelineBlock = lazy(() =>
	import(/* webpackChunkName: "TimelineBlock" */ './TimelineBlock'),
);
const RowBlock = lazy(() =>
	import(/* webpackChunkName: "RowBlock" */ './RowBlock'),
);
const ToastBlock = lazy(() =>
	import(/* webpackChunkName: "ToastBlock" */ './ToastBlock'),
);

const componentToBlock = (path: string, render: RenderType) => (
	component: BuildingBlocks,
	index?: number,
) => {
	switch (component.type) {
		case 'verticalContainer':
			return (
				<GroupingBlock
					key={index}
					path={path}
					components={component.components}
					render={render}
				/>
			);

		case 'inlineComponents':
			return (
				<RowBlock
					key={index}
					path={path}
					components={component.components}
					render={render}
				/>
			);

		case 'fields':
			return (
				<FormBlock
					key={index}
					path={path}
					render={render}
					fields={component.fields}
					submitButtonText={component.submitButtonText}
					submitButtonIcon={component.submitButtonIcon}
				/>
			);

		case 'eventsTable':
			return (
				<EventsTableBlock
					key={index}
					sagaEventIds={component.sagaEventIds}
				/>
			);

		case 'table':
			return (
				<TableBlock
					key={index}
					data={component.data}
					columns={component.columns}
				/>
			);

		case 'graph':
			return (
				<GraphBlock
					key={index}
					data={component.data}
					series={component.series}
				/>
			);

		case 'pieChart':
			return (
				<PieChartBlock
					key={index}
					data={component.data}
					series={component.series}
				/>
			);

		case 'text':
			return (
				<TextBlock
					key={index}
					text={component.text}
					elementType={component.elementType}
				/>
			);

		case 'redirect':
			return <RedirectBlock key={index} link={component.link} />;

		case 'map':
			return (
				<GeoMapBlock
					key={index}
					pins={component.pins}
					lines={component.lines}
				/>
			);

		case 'image':
			return (
				<ImageBlock
					key={index}
					url={component.url}
					width={component.width}
				/>
			);

		case 'timeline':
			return (
				<TimelineBlock
					key={index}
					path={path}
					items={component.items}
					render={render}
				/>
			);

		case 'toast':
			return (
				<ToastBlock
					key={index}
					message={component.message}
					variant={component.variant}
				/>
			);

		default:
			console.error('Unsupported component', component);
			return null;
	}
};

export { componentToBlock };
