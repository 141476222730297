import React, { Suspense } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import {
	Button,
	Link,
	Loading,
	hasPresentKey,
	t,
	useToggle,
} from '@persequor-com/common';

import { useRoutesState } from '../../RoutesContext';
import Builder from '../../builder/Builder';

type DialogProps = import('@persequor-com/common').WithChildren<
	import('@persequor-com/common').DialogLink
>;

const DialogLink = (props: DialogProps) => {
	const { routes } = useRoutesState();
	const [showDialog, toggleShowDialog] = useToggle(false);
	const { children } = props;

	const extensionRoute = routes
		.filter(hasPresentKey('path'))
		.find(
			(route) =>
				props.render === 'extension' && route.id === props.pageId,
		);

	return (
		<>
			<Link
				onClick={(
					e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
				) => {
					e.stopPropagation();
					toggleShowDialog();
				}}
			>
				{children}
			</Link>
			<Dialog
				open={showDialog}
				onClose={toggleShowDialog}
				maxWidth="lg"
				fullWidth
				onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
					e.stopPropagation()
				}
			>
				{props.render === 'preformatted' || props.render === 'plain' ? (
					<>
						<DialogTitle>{props.header}</DialogTitle>
						<DialogContent>
							{props.render === 'preformatted' ? (
								<pre>{props.text}</pre>
							) : (
								<>{props.text}</>
							)}
						</DialogContent>
						<DialogActions>
							<Button
								color="primary"
								onClick={toggleShowDialog}
								data-testid="close-dialog"
							>{t`Close`}</Button>
						</DialogActions>
					</>
				) : props.render === 'extension' &&
				  extensionRoute &&
				  'builder' in extensionRoute ? (
					<Suspense fallback={<Loading />}>
						<Builder
							path={extensionRoute.path}
							endpoint={extensionRoute.endpoint}
							params={props.parameters}
							id={extensionRoute.id}
							render="dialog"
						/>
					</Suspense>
				) : null}
			</Dialog>
		</>
	);
};

export default DialogLink;
