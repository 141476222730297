import React from 'react';
import {
	AppBar as MuiAppBar,
	Hidden,
	Toolbar,
	IconButton,
} from '@material-ui/core';
import { Icon } from '@persequor-com/common';
import styled from 'styled-components/macro';

import Logo from './images/Logo';
import UserMenu from './menu/UserMenu';
import { useMenu } from './menu/MenuContext';
import { useConfig } from './config/ConfigContext';

const AppBar = styled(MuiAppBar)`
	z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const LogoContainer = styled.span`
	height: 48px;
	flex-grow: 1;
`;

const PaddedIcon = styled(Icon)`
	margin-left: 1em;
`;

const PaddedDiv = styled.div`
	margin-left: 1em;
`;

const Top = () => {
	const { setOpen } = useMenu();
	const disableMenu = useConfig('disableMenus');
	const frontendConfig = useConfig('frontendConfig');
	if (disableMenu) return null;

	return (
		<AppBar position="sticky">
			<Toolbar>
				<Hidden smDown implementation="css">
					{frontendConfig?.theme.logoIcon && (
						<PaddedIcon
							name={frontendConfig.theme.logoIcon.name}
							color={frontendConfig.theme.logoIcon.color}
						/>
					)}
				</Hidden>
				<Hidden smDown implementation="css">
					{frontendConfig?.theme.logoText && (
						<PaddedDiv>{frontendConfig.theme.logoText}</PaddedDiv>
					)}
				</Hidden>
				<Hidden mdUp implementation="css">
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => setOpen(true)}
					>
						<Icon name="bars" />
					</IconButton>
				</Hidden>
				<LogoContainer>
					<Logo />
				</LogoContainer>
				<UserMenu />
			</Toolbar>
		</AppBar>
	);
};

export default Top;
