import { isDefined } from '@persequor-com/common';

import {
	ComponentSchema,
	EventDetailsExtension,
} from '../../types/ComponentSchema';

const getEventDetailsExtensions = (
	components: ComponentSchema[],
): EventDetailsExtension[] =>
	components
		.map((component) => component?.ui?.eventDetails)
		.filter(isDefined)
		.reduce((acc, val) => acc.concat(val), []) //.flat()
		.sort((a, b) => a.order - b.order);

export { getEventDetailsExtensions };
