import React from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import styled from 'styled-components/macro';

type MobileMenuProps = {
	open: boolean;
	handleClose: (a: boolean) => void;
	children: React.ReactNode;
};

const Drawer = styled(MuiDrawer)`
	width: ${(p) => p.theme.menu.width};
	flex-shrink: 0;

	& .MuiDrawer-paper {
		width: ${(p) => p.theme.menu.width};
		justify-content: space-between;
	}
`;

const MobileMenu = ({ open, handleClose, children }: MobileMenuProps) => (
	<Drawer
		variant="temporary"
		anchor="left"
		open={open}
		onClose={() => handleClose(false)}
		ModalProps={{
			keepMounted: true,
		}}
	>
		{children}
	</Drawer>
);

export default MobileMenu;
