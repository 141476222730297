import React from 'react';

import NotFound from './NotFound';
import VocabularyDetails from '../vocabulary-details/VocabularyDetails';

type VocabularyBackedTypeValue = import('@persequor-com/common').VocabularyBackedTypeValue;
type VocabularyBackedValue = import('@persequor-com/common').VocabularyBackedValue;

type VocabularyBackedValueProps = {
	useId?: boolean;
	item:
		| VocabularyBackedValue
		| VocabularyBackedTypeValue
		| VocabularyBackedTypeValue[]
		| string;
	vocabularyName?: string;
};

const VocabularyBackedValue = ({
	useId,
	item,
	vocabularyName,
}: VocabularyBackedValueProps) => {
	if (Array.isArray(item)) {
		return (
			<>
				{item.map((i) => (
					<VocabularyBackedValue
						key={i.value}
						useId={useId}
						item={i}
						vocabularyName={vocabularyName}
					/>
				))}
			</>
		);
	} else if (typeof item === 'string') {
		return (
			<VocabularyDetails
				useId={useId}
				itemId={item}
				vocabularyId={item}
				vocabularyName={vocabularyName}
			/>
		);
	} else if ('type' in item) {
		// VocabularyBackedTypeValue
		return (
			<div key={item.type}>
				<strong>
					{useId ? item.type : item.typeDisplayName || item.type}:
				</strong>{' '}
				<VocabularyDetails
					useId={useId}
					itemId={item.value}
					vocabularyId={item.value}
					vocabularyName={vocabularyName}
					displayName={item.displayName}
				/>
			</div>
		);
	} else if ('value' in item) {
		// VocabularyBackedValue
		return (
			<VocabularyDetails
				useId={useId}
				itemId={item.value}
				vocabularyId={item.value}
				vocabularyName={vocabularyName}
				displayName={item.displayName}
			/>
		);
	} else {
		return <NotFound />;
	}
};

export default VocabularyBackedValue;
