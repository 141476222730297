import React from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import styled from 'styled-components/macro';

type DesktopMenuProps = {
	children: React.ReactNode;
};

const Drawer = styled(MuiDrawer)`
	width: ${(p) => p.theme.menu.width};
	position: absolute;

	& .MuiDrawer-paper {
		width: ${(p) => p.theme.menu.width};
		top: 48px;
		height: calc(100vh - 48px);
		justify-content: space-between;
	}
`;

const DesktopMenu = ({ children }: DesktopMenuProps) => (
	<Drawer variant="permanent" anchor="left">
		{children}
	</Drawer>
);

export default DesktopMenu;
