import { evolve, pipe } from 'ramda';
import { prepareDate, removeAllEmptyProperties } from '@persequor-com/common';
import { DateTime } from 'luxon';

import { useFrontendSupportApi } from '../hooks/useSaga';

type WrappedEvent = import('@persequor-com/common').WrappedEvent;

type EventParams = {
	actions?: ('ADD' | 'OBSERVE' | 'DELETE')[];
	anyEpcs?: string[];
	anyFreeText?: string[];
	bizLocations?: string[];
	bizSteps?: string[];
	bizTransactions?: string[];
	destinations?: string[];
	epcClasses?: string[];
	epcisEventIds?: string[];
	epcs?: string[];
	eventTimeFrom?: number | DateTime;
	eventTimeTo?: number | DateTime;
	eventTypes?: ('TransactionEvent' | 'AggregationEvent' | 'ObjectEvent')[];
	freeText?: string[];
	indexHashes?: string[];
	parents?: string[];
	readPoints?: string[];
	recordTimeFrom?: number | DateTime;
	recordTimeTo?: number | DateTime;
	sagaEventIds?: string[];
	sources?: string[];
};

type EventOptions = {
	timestamp?: number;
	ready?: boolean;
	withExtensions?: boolean;
	withListExtensions?: boolean;
};

const useEvents = (params: EventParams, options?: EventOptions) => {
	const [wrappedEvents = [], loading, error] = useFrontendSupportApi<
		WrappedEvent[]
	>({
		endpoint: '/events/query',
		options: {
			method: 'POST',
			body: JSON.stringify(prepareParams(params)),
		},
		params: removeAllEmptyProperties({
			withExtensions: options?.withExtensions,
			withListExtensions: options?.withListExtensions,
		}),
		ready: options?.ready ?? hasSomeParams(params),
		timestamp: options?.timestamp,
	});

	return { wrappedEvents, loading, error };
};

const hasSomeParams = (params: EventParams) =>
	Object.keys(removeAllEmptyProperties(params)).length > 0;

const prepareParams = pipe(
	removeAllEmptyProperties,
	evolve({
		eventTimeFrom: prepareDate,
		eventTimeTo: prepareDate,
		recordTimeFrom: prepareDate,
		recordTimeTo: prepareDate,
	}),
);

export default useEvents;
