import React, { createContext, useContext, useState } from 'react';
import { t } from '@persequor-com/common';

import { useFrontendSupportApi } from '../hooks/useSaga';

type ConfigProviderProps = { children: React.ReactNode };

type LocalFrontendConfiguration = {
	disableMenus?: boolean;
};

type FrontendConfiguration = {
	base: string;
	menuSections?: import('../types/MenuSchema').Section[];
	frontendConfig?: {
		theme: {
			baseColor: number;
			logoText?: string;
			logoIcon?: import('@persequor-com/common').EventExtensionDataIcon;
		};
		disableBizTransactionsVocabulary: boolean;
		mapboxApiAccessToken?: string;
		mapStyle?: string;
	};
	loginConfiguration?: import('../types/ComponentSchema').LoginConfiguration;
};

export type ConfigStateContextValue = LocalFrontendConfiguration &
	FrontendConfiguration & { loading: boolean };

type ConfigActionsContextValue = {
	setLocalConfig: React.Dispatch<
		React.SetStateAction<LocalFrontendConfiguration>
	>;
};

type RuntimeConfig = {
	apiBase?: string;
};

const runtimeConfig: RuntimeConfig = (window as any).runtimeConfig;
const base = runtimeConfig?.apiBase ?? process.env.REACT_APP_API_BASE ?? '';

const ConfigStateContext = createContext<ConfigStateContextValue>({
	base,
	loading: true,
});

const ConfigActionContext = createContext<
	ConfigActionsContextValue | undefined
>(undefined);

const ConfigProvider = ({ children }: ConfigProviderProps) => {
	const [backendConfig, loading, error] = useFrontendSupportApi<
		FrontendConfiguration
	>({
		endpoint: '/configuration',
	});

	const [localConfig, setLocalConfig] = useState<LocalFrontendConfiguration>({
		disableMenus: false,
	});

	if (error) {
		// No way to recover from missing configuration response
		return (
			<>
				<h1>{t`Failed fetching configuration`}</h1>
				<p>{t`Is SAGA running and accessible?`}</p>
			</>
		);
	}

	return (
		<ConfigStateContext.Provider
			value={{ base, ...backendConfig, ...localConfig, loading }}
		>
			<ConfigActionContext.Provider value={{ setLocalConfig }}>
				{children}
			</ConfigActionContext.Provider>
		</ConfigStateContext.Provider>
	);
};

const useConfig = <K extends keyof ConfigStateContextValue>(key: K) => {
	const context = useContext(ConfigStateContext);

	return context[key];
};

const useConfigActions = () => {
	const context = useContext(ConfigActionContext);

	if (context === undefined) {
		throw new Error(
			'useConfigActions must be used within a ConfigProvider',
		);
	}

	return context;
};

export { ConfigProvider, useConfig, useConfigActions };
