import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Icon, PrimaryButton, t } from '@persequor-com/common';

type ErrorBoundaryState = {
	hasError: boolean;
	error?: Error;
};

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
	state: ErrorBoundaryState = {
		hasError: false,
		error: undefined,
	};

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		console.error(info, error);
	}

	reload = () => {
		window.location.reload(true);
	};

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Container maxWidth="sm">
					<Grid container direction="column" alignItems="center">
						<h1>{t`Error!`}</h1>

						<Icon name="cog" size="5x" spin />

						<p>
							{t`Unfortunately, something went wrong. Please let us know about the error so that we can have it fixed. In the meantime, try reloading the application.`}
						</p>

						<small>
							{this.state.error && this.state.error.message}
						</small>

						<small>{t`UI v${process.env.REACT_APP_VERSION}`}</small>

						<Grid container direction="row" justify="center">
							<PrimaryButton onClick={this.reload} icon="redo">
								{t`Try reloading`}
							</PrimaryButton>
						</Grid>
					</Grid>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
