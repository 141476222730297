import React from 'react';
import { DialogTitle, Typography } from '@material-ui/core';
import { Block } from '@persequor-com/common';

type PageHeaderProps = {
	render: import('./Builder').RenderType;
	children: React.ReactNode;
};

const PageHeader = ({ render, children }: PageHeaderProps) => {
	if (!children) return null;

	switch (render) {
		case 'normal':
			return (
				<Block>
					<Typography variant="h2">{children}</Typography>
				</Block>
			);

		case 'dialog':
			return <DialogTitle>{children}</DialogTitle>;
	}
};

export default PageHeader;
