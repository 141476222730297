import React from 'react';
import {
	Link,
	NoData,
	Popover,
	extractVocabularyElements,
	extractVocabularyAttributes,
	getHead,
} from '@persequor-com/common';

import VocabularyDetailsContent from './VocabularyDetailsContent';
import VocabularyIdentifier from './VocabularyIdentifier';

export type VocabularyDetailsPreloadedProps = {
	hideButtons?: boolean;
	itemId?: string;
	idLabel?: string;
	placement?: import('@material-ui/core').PopperPlacementType;
	useId?: boolean;
	vocabulary?: import('@persequor-com/common').Vocabulary;
	displayName?: string;
};

const VocabularyDetailsPreloaded = ({
	hideButtons,
	itemId,
	idLabel,
	placement,
	useId,
	vocabulary,
	displayName,
}: VocabularyDetailsPreloadedProps) => {
	const elements = extractVocabularyElements(vocabulary);

	if (!itemId) return null;

	const attributes = extractVocabularyAttributes(vocabulary);
	const label =
		displayName ?? attributes?.find((attr) => attr.id === 'name')?.value;

	return (
		<Popover
			id={`vocabularyPopover-${itemId}`}
			placement={placement}
			anchor={
				<Link>
					<VocabularyIdentifier
						displayName={label}
						icon="link"
						id={itemId}
						useId={useId}
					/>
				</Link>
			}
		>
			{elements.length === 0 || !vocabulary ? (
				<NoData />
			) : (
				<VocabularyDetailsContent
					elements={elements}
					itemId={itemId}
					idLabel={idLabel}
					vocabularyName={getHead(vocabulary)?.type ?? ''}
					hideButtons={hideButtons}
					loading={false}
				/>
			)}
		</Popover>
	);
};

export default VocabularyDetailsPreloaded;
