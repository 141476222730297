import { useRoutesState } from '../RoutesContext';

const useRoutePath = (id: string) => {
	const { routes } = useRoutesState();

	const route = routes.find((route) => route.id === id);

	return route?.path;
};

export default useRoutePath;
