import React from 'react';
import styled from 'styled-components/macro';
import { Skeleton } from '@material-ui/lab';

type RowProps = {
	children?: React.ReactNode;
	header?: string;
	loading?: boolean;
};

const RowHeader = styled.div`
	align-items: center;
	border-bottom: thin solid #e6e6e6;
	display: flex;
	grid-column: 1;
	padding: 3px 1em 3px 0;
	word-break: break-word;
`;

const RowContent = styled.div`
	border-bottom: thin solid #e6e6e6;
	grid-column: 2;
	padding: 3px 0;
	word-break: break-word;
`;

const Row = ({ children, header, loading }: RowProps) => {
	if (loading) {
		return (
			<>
				<RowHeader>
					<Skeleton variant="text" width="75%" />
				</RowHeader>
				<RowContent>
					<Skeleton variant="text" width="50%" />
				</RowContent>
			</>
		);
	} else {
		if (!children || (Array.isArray(children) && children.length < 1)) {
			return null;
		} else {
			return (
				<>
					<RowHeader>{header}</RowHeader>
					<RowContent>{children}</RowContent>
				</>
			);
		}
	}
};

export default Row;
