import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * Calls function with param when window resized to desktop size
 * @param {*} func
 * @param {*} param
 */
const useDesktop = (func: Function, param: any) => {
	const theme = useTheme();
	const desktopSize = useMediaQuery(theme.breakpoints.up('md'));

	useEffect(() => {
		if (desktopSize) func(param);
	}, [desktopSize, func, param]);
};

export default useDesktop;
