import React from 'react';

import RenderByType from './RenderByType';
import ExtensionLink from './ExtensionLink';

type EventExtensionData = import('@persequor-com/common').EventExtensionData;

const ExtensionValue = (props: EventExtensionData) => (
	<ExtensionLink {...props}>
		<RenderByType {...props} />
	</ExtensionLink>
);

export default ExtensionValue;
