import styled from 'styled-components/macro';

type ImageProps = {
	height?: number;
	width?: number;
};

const Image = styled.img<ImageProps>`
	width: ${(p) => p.width}px;
	height: ${(p) => p.height}px;
`;

export default Image;
