import React from 'react';

import StringValue from '../value-items/StringValue';
import ValueDates from '../value-items/ValueDates';
import VocabularyBackedValue from '../value-items/VocabularyBackedValue';

type EventDetailsSectionRow = import('../../types/ComponentSchema').EventDetailsSectionRow;

type CoreValueProps = {
	data: EventDetailsSectionRow;
	eventValue: <T extends unknown>(path: string) => T | undefined;
	useId: boolean;
};

const CoreValue = ({ data, eventValue, useId }: CoreValueProps) => {
	switch (data.type) {
		case 'string': {
			return <StringValue text={eventValue<string>(data.value)} />;
		}

		case 'date': {
			return <ValueDates date={eventValue<string>(data.value)} />;
		}

		case 'vocabulary-backed-value': {
			type ItemType = React.ComponentProps<
				typeof VocabularyBackedValue
			>['item'];

			const value = eventValue<ItemType>(data.value);

			if (!value || (Array.isArray(value) && value.length < 1)) {
				return null;
			} else {
				return (
					<VocabularyBackedValue
						item={value}
						useId={useId}
						vocabularyName={data.vocabularyName}
					/>
				);
			}
		}

		default:
			console.error('Unhandled row type', data);
			throw new Error(`Unhandled row type`);
	}
};

export default CoreValue;
