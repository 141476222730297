import { useEffect, useState } from 'react';
import { i18n } from '@persequor-com/common';

import translateYup from './translateYup';

const determineLocale = () =>
	i18n.getLocaleFromURL() || i18n.getLocaleFromBrowser();

const loadLocale = async (lang: string) => {
	return import(
		/* webpackChunkName: "i18n/[request]" */
		`./generated/${lang}.json`
	).then((data) => data.default);
};

const useInternationalisation = () => {
	const [loadingTranslations, setLoadingTranslations] = useState(true);

	useEffect(() => {
		const language = determineLocale();

		const fetchLocales = async () => {
			try {
				const translation = await loadLocale(language);
				i18n.initialise(language, translation);
				setLoadingTranslations(false);
				console.info(`i18n: Loaded "${language}" locale`);
			} catch (error) {
				setLoadingTranslations(false);
				console.error(
					`i18n: Could not load locale "${language}"`,
					error,
				);
			}
		};

		fetchLocales();
		translateYup();
	}, []);
	return { loadingTranslations };
};

export default useInternationalisation;
