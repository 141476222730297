import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getHead, isPresent } from '@persequor-com/common';

import EventDetails from './EventDetails';
import useEvents from '../../hooks/useEvents';

type WrappedEvent = import('@persequor-com/common').WrappedEvent;

type ShowEventDetailsProps = {
	open: boolean;
	sagaEventId?: string;
	sagaEvent?: WrappedEvent;
	hideEvent: () => void;
};

type ShowEventProviderProps = { children: React.ReactNode };

const ShowEventActionsContext = createContext<
	((sagaEventId: string) => void) | undefined
>(undefined);

const ShowEventProvider = ({ children }: ShowEventProviderProps) => {
	const history = useHistory();
	const location = useLocation();
	const [open, setOpen] = useState<boolean>(false);
	const [sagaEventId, setSagaEventId] = useState<string>();
	const [sagaEvent, setSagaEvent] = useState<WrappedEvent>();

	const { hash, pathname, search, state } = location;

	const showEvent = (sagaEventId: string) => {
		history.push(
			history.createHref({
				pathname,
				search,
				hash: sagaEventId,
			}),
			state,
		);

		setSagaEvent(undefined);
	};

	const hideEvent = () => {
		history.push(history.createHref({ pathname, search }), state);
	};

	// Show/hide event based on changes to URL hash containing SAGA Event ID
	useEffect(() => {
		if (hash) {
			setOpen(true);
			setSagaEventId(hash.replace(/^#/, ''));
		} else {
			setOpen(false);
			setSagaEventId(undefined);
			setSagaEvent(undefined);
		}
	}, [hash]);

	return (
		<ShowEventActionsContext.Provider value={showEvent}>
			{children}
			<ShowEventDetails
				open={open}
				sagaEventId={sagaEventId}
				sagaEvent={sagaEvent}
				hideEvent={hideEvent}
			/>
		</ShowEventActionsContext.Provider>
	);
};

const useShowEvent = () => {
	const context = useContext(ShowEventActionsContext);

	if (context === undefined) {
		throw new Error('useShowEvent must be used within a ShowEventProvider');
	}
	return context;
};

const ShowEventDetails = ({
	open,
	sagaEventId,
	sagaEvent,
	hideEvent,
}: ShowEventDetailsProps) => {
	const { wrappedEvents, loading } = useEvents(
		{
			sagaEventIds: [sagaEventId].filter(isPresent),
		},
		{
			ready: open && !!sagaEventId && !sagaEvent,
			withExtensions: true,
		},
	);

	return (
		<EventDetails
			wrappedEvent={sagaEvent ?? getHead(wrappedEvents)}
			open={open}
			hideEvent={() => hideEvent()}
			loading={loading}
		/>
	);
};

export { ShowEventProvider, useShowEvent };
