import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Calls func with param on route change
 * @param {*} func
 * @param {*} param
 */
const useRouteChange = (func: Function, param: any) => {
	const location = useLocation();

	useEffect(() => {
		func(param);
	}, [location.pathname, location.key, func, param]);
};

export default useRouteChange;
