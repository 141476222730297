import styled from 'styled-components/macro';
import { kebab } from '@persequor-com/common';

type ContainerProps = Pick<
	import('@persequor-com/common').EventExtensionDataContainer,
	'direction' | 'align'
>;

const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: ${(p) => p.direction};
	justify-content: ${(p) => kebab(p.align)};
`;

export default Container;
