import React from 'react';
import { FormattedDate, t } from '@persequor-com/common';

type ValueDatesProps = {
	date?: string;
};

const ValueDates = ({ date }: ValueDatesProps) =>
	date ? (
		<>
			<strong>UTC: </strong>
			<FormattedDate tz="utc">{date}</FormattedDate>
			<br />
			<strong>{t`Local`}: </strong>
			<FormattedDate tz="local">{date}</FormattedDate>
		</>
	) : null;

export default ValueDates;
