/* eslint-disable @typescript-eslint/no-empty-interface */
import { createMuiTheme, Theme } from '@material-ui/core';

interface SagaTheme {
	menu: {
		width: string;
	};
}

const createTheme = (baseColor: number, menuWidth: string) =>
	createMuiTheme({
		menu: {
			width: menuWidth,
		},
		palette: {
			primary: {
				main: `hsl(${baseColor}, 50%, 31%)`, //'#402877',
			},
			secondary: {
				main: `hsl(${baseColor}, 43%, 62%)`, //'#907CBE',
			},
			info: {
				main: '#7cb398',
			},
			success: {
				main: '#cef2e5',
			},
		},
		spacing: 2,
		typography: {
			h1: {
				fontSize: '3.4rem',
			},
			h2: {
				fontSize: '3.0rem',
			},
			h3: {
				fontSize: '2.0rem',
			},
			h4: {
				fontSize: '1.6rem',
			},
		},
		props: {
			MuiButton: {
				size: 'small',
			},
			MuiFilledInput: {
				margin: 'dense',
			},
			MuiFormControl: {
				margin: 'dense',
			},
			MuiFormHelperText: {
				margin: 'dense',
			},
			MuiIconButton: {
				size: 'small',
			},
			MuiInputBase: {
				margin: 'dense',
			},
			MuiInputLabel: {
				margin: 'dense',
			},
			MuiListItem: {
				dense: true,
			},
			MuiOutlinedInput: {
				margin: 'dense',
			},
			MuiFab: {
				size: 'small',
			},
			MuiTable: {
				size: 'small',
			},
			MuiTextField: {
				margin: 'dense',
			},
			MuiToolbar: {
				variant: 'dense',
			},
		},
		overrides: {
			MuiIconButton: {
				sizeSmall: {
					// Adjust spacing to reach minimal touch target hitbox
					marginLeft: 4,
					marginRight: 4,
					padding: 12,
				},
			},
		},
	});

declare module '@material-ui/core/styles/createMuiTheme' {
	interface Theme extends SagaTheme {}
	interface ThemeOptions extends SagaTheme {}
}

declare module 'styled-components' {
	export interface DefaultTheme extends SagaTheme, Theme {}
}

export default createTheme;
