import { ComponentSchema, PathFunction } from '../types/ComponentSchema';

const getComponent = async (component: string): Promise<ComponentSchema> => {
	const comp = await import(
		/* webpackChunkName: "[request]" */
		/* webpackInclude: /index\.(js|ts)$/ */
		`../${component}/`
	);

	if (!comp.config) {
		throw new Error(`Component "${component}" hasn't exported a config`);
	}

	return comp.config;
};

const prepareComponentPath = (path: PathFunction) =>
	typeof path === 'string'
		? // eslint-disable-next-line no-new-func
		  new Function('itemId', path)
		: path;

export { getComponent, prepareComponentPath };
