import React, { createContext, useContext, useState, useEffect } from 'react';

import { useSessionState } from './useSessionState';
import { logout } from './authFunctions';
import { useConfig } from '../config/ConfigContext';

type AuthProviderProps = { children: React.ReactNode };

type AuthStateContextValue = {
	authStatus: AuthStatus;
};

type AuthStatus = 'initialising' | 'loggedIn' | 'loggedOut' | 'sessionExpired';

type AuthActionsContextValue = {
	logout: () => void;
	setAuthStatus: (status: AuthStatus) => void;
	checkSession: () => void;
};

const calledOutsideProvider = (func: string) => () =>
	console.error(`${func}() must be called within an AuthProvider`);

const AuthStateContext = createContext<AuthStateContextValue | undefined>(
	undefined,
);

const AuthActionsContext = createContext<AuthActionsContextValue>({
	logout: calledOutsideProvider('logout'),
	setAuthStatus: calledOutsideProvider('setAuthStatus'),
	checkSession: calledOutsideProvider('checkSession'),
});

const AuthProvider = ({ children }: AuthProviderProps) => {
	const base = useConfig('base');
	const [authStatus, setAuthStatus] = useState<AuthStatus>('initialising');
	const { sessionValid, checkingSession, checkSession } = useSessionState();

	useEffect(() => {
		if (!checkingSession && sessionValid !== undefined) {
			if (authStatus === 'loggedIn' && !sessionValid) {
				setAuthStatus('sessionExpired');
			} else {
				setAuthStatus(sessionValid ? 'loggedIn' : 'loggedOut');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkingSession, sessionValid]);

	return (
		<AuthStateContext.Provider value={{ authStatus }}>
			<AuthActionsContext.Provider
				value={{
					logout: () => logout(base),
					setAuthStatus,
					checkSession,
				}}
			>
				{children}
			</AuthActionsContext.Provider>
		</AuthStateContext.Provider>
	);
};

const useAuthState = () => {
	const context = useContext(AuthStateContext);

	if (context === undefined) {
		throw new Error('useAuthState must be used within an AuthProvider');
	}
	return context;
};

const useAuthActions = () => {
	const context = useContext(AuthActionsContext);

	return context;
};

export { AuthProvider, useAuthState, useAuthActions };
