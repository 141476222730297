import React, { createContext, useContext, useEffect, useState } from 'react';

import { getComponent } from './utils/componentUtils';
import { useFrontendSupportApi } from './hooks/useSaga';

type ComponentSchema = import('./types/ComponentSchema').ComponentSchema;

type ExtensionsContextValue = {
	extensions: ComponentSchema[];
	loading: boolean;
};

type ExtensionsProviderProps = { children: React.ReactNode };

const ExtensionsContext = createContext<ExtensionsContextValue | undefined>(
	undefined,
);

const ExtensionsProvider = ({ children }: ExtensionsProviderProps) => {
	const [extensions, setExtensions] = useState<ComponentSchema[]>([]);
	const [enabledExtensions, setEnabledExtensions] = useState<
		string[] | undefined
	>(undefined);
	const [localExtensionsLoading, setLocalExtensionsLoading] = useState(true);
	const [backendExtensions, backendExtensionsLoading] = useFrontendSupportApi<
		ComponentSchema[]
	>({
		endpoint: 'extensions',
	});

	useEffect(() => {
		const getExtensionsConfig = async () => {
			// Trick webpack into allowing conditional loading of
			// extensions/index.js, which might not be present.
			const component = 'extensions';
			try {
				const config = await import(`./${component}/index.js`);
				setEnabledExtensions(config.enabled);
			} catch (err) {
				setLocalExtensionsLoading(false);
			}
		};

		getExtensionsConfig();
	}, []);

	useEffect(() => {
		if (Array.isArray(backendExtensions)) {
			setExtensions((e) => [...backendExtensions, ...e]);
		}
	}, [backendExtensions]);

	useEffect(() => {
		const getEnabledExtensions = async () => {
			if (Array.isArray(enabledExtensions)) {
				const extensions = await Promise.all(
					enabledExtensions.map((e) =>
						getComponent(`extensions/${e}`),
					),
				);
				setExtensions((e) => [...e, ...extensions]);
				setLocalExtensionsLoading(false);
			}
		};

		getEnabledExtensions();
	}, [enabledExtensions]);

	const loading = localExtensionsLoading || backendExtensionsLoading;

	return (
		<ExtensionsContext.Provider value={{ extensions, loading }}>
			{children}
		</ExtensionsContext.Provider>
	);
};

const useExtensions = () => {
	const context = useContext(ExtensionsContext);

	if (context === undefined) {
		throw new Error(
			'useExtensions must be used within an ExtensionsProvider',
		);
	}
	return context;
};

export { ExtensionsProvider, useExtensions };
