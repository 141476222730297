import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { FormattedDate, Icon } from '@persequor-com/common';

import WithIcon from './WithIcon';
import Image from './Image';
import Container from './Container';
import ExtensionValue from './ExtensionValue';

type EventExtensionData = import('@persequor-com/common').EventExtensionData;

const formattedNumber = (number?: number | null) =>
	typeof number === 'number'
		? new Intl.NumberFormat(navigator.language).format(number)
		: undefined;

const RenderByType = (data: EventExtensionData) => {
	switch (data.type) {
		case 'text': {
			return <>{data.text}</>;
		}

		case 'iconButton': {
			const { icon } = data;

			return (
				<Tooltip title={data.text}>
					<IconButton size="small">
						<Icon
							{...icon}
							testid={data.text}
							name={data.icon.name ?? 'exclamation-circle'}
						/>
					</IconButton>
				</Tooltip>
			);
		}

		case 'number': {
			return <>{formattedNumber(data.number)}</>;
		}

		case 'dateTime': {
			return <FormattedDate tz={data.tz}>{data.dateTime}</FormattedDate>;
		}

		case 'icon': {
			return (
				<WithIcon icon={data.icon}>
					{data.text ?? formattedNumber(data.number)}
				</WithIcon>
			);
		}

		case 'image':
			return (
				<Image src={data.url} height={data.height} width={data.width} />
			);

		case 'container': {
			return (
				<Container direction={data.direction} align={data.align}>
					{data.items.map((item, index) => (
						<ExtensionValue key={index} {...item} />
					))}
				</Container>
			);
		}

		default:
			console.error('Unhandled type', data);
			return null;
	}
};

export default RenderByType;
