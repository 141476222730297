import React, { createContext, useCallback, useContext } from 'react';
import { fetchApi } from '@persequor-com/common';

import { useAuthActions } from '../auth/AuthContext';
import { useConfig } from './ConfigContext';
import { apiUrl } from './configFunctions';

type ApiProviderProps = { children: React.ReactNode };

type SagaService = import('./configFunctions').SagaService;

type FetchSagaApiProps = Omit<
	import('@persequor-com/common').FetchProps,
	'url'
> & {
	service: SagaService;
	endpoint: string;
};
export type FetchSagaApi = <T>(params: FetchSagaApiProps) => Promise<T>;

const ApiContext = createContext<FetchSagaApi | undefined>(undefined);

const ApiProvider = ({ children }: ApiProviderProps) => {
	const base = useConfig('base');
	const { checkSession } = useAuthActions();

	const fetchSagaApi = useCallback<FetchSagaApi>(
		(params: FetchSagaApiProps) =>
			fetchApi(checkSession)({
				...params,
				url: apiUrl(base)(params.service, params.endpoint),
			}),
		[checkSession, base],
	);

	return (
		<ApiContext.Provider value={fetchSagaApi}>
			{children}
		</ApiContext.Provider>
	);
};

const useApi = () => {
	const context = useContext(ApiContext);

	if (context === undefined) {
		throw new Error('useApi must be used within an ApiProvider');
	}

	return context;
};

export { ApiProvider, useApi };
