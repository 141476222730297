import React from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { Icon, Link, addQueryParameters, t } from '@persequor-com/common';

import useRoutePath from '../../hooks/useRoutePath';

type PageBuilderLinkProps = import('@persequor-com/common').WithOptionalChildren<
	import('@persequor-com/common').PageBuilderLink
> & { redirect?: boolean };

const PageBuilderLink = ({
	children,
	pageBuilderId,
	parameters,
	redirect = false,
}: PageBuilderLinkProps) => {
	const componentPath = useRoutePath(pageBuilderId);

	if (!componentPath) {
		const error = t`No page with id "${pageBuilderId}" has been registered`;

		console.error(error);

		return (
			<>
				<Icon name="exclamation-circle" color="red" /> {error}
			</>
		);
	}

	const url = addQueryParameters(componentPath, parameters);

	return redirect ? (
		<Redirect to={url} />
	) : (
		<Link
			as={RouterLink}
			to={url}
			onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
				e.stopPropagation()
			}
		>
			{children}
		</Link>
	);
};

export default PageBuilderLink;
