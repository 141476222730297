import React from 'react';
import styled from 'styled-components/macro';

const SectionGrid = styled.div`
	display: grid;
	grid-template-columns: 35% 65%;
	grid-template-rows: auto;
`;

const SectionHeader = styled.h3`
	border-bottom: thin solid #e6e6e6;
	margin-bottom: 0;
	padding-bottom: 3px;
`;

const StyledSection = styled.section<{ disabled: boolean }>`
	opacity: ${(p) => (p.disabled ? '0.5' : '1')};
	pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;

type SectionProps = {
	header: string;
	children: React.ReactNode;
	visibility?: import('@persequor-com/common').Visibility;
};

const Section = ({
	header,
	visibility = 'VISIBLE',
	children,
}: SectionProps) => {
	if (visibility === 'HIDDEN') return null;

	return (
		<StyledSection disabled={visibility === 'DISABLED'}>
			<SectionHeader>{header}</SectionHeader>
			<span>{children}</span>
		</StyledSection>
	);
};

export default Section;
export { SectionGrid };
