import React from 'react';

import VocabularyDetailsAsync from './VocabularyDetailsAsync';
import VocabularyDetailsPreloaded from './VocabularyDetailsPreloaded';

type VocabularyDetailsProps =
	| import('./VocabularyDetailsAsync').VocabularyDetailsAsyncProps
	| import('./VocabularyDetailsPreloaded').VocabularyDetailsPreloadedProps;

const VocabularyDetails = (props: VocabularyDetailsProps) =>
	'vocabularyName' in props ? (
		// Async vocabulary data
		<VocabularyDetailsAsync {...props} />
	) : (
		// Preloaded vocabulary data
		<VocabularyDetailsPreloaded {...props} />
	);

export default VocabularyDetails;
