import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import styled from 'styled-components/macro';
import { FormikTextField, PrimaryButton, t } from '@persequor-com/common';
import { useSnackbar } from 'notistack';

import { useAuthActions } from './AuthContext';
import { createSession } from './authFunctions';
import { useConfig } from '../config/ConfigContext';

type Credentials = { username: string; password: string };

const Paper = styled.div`
	margin-top: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoginButton = styled(PrimaryButton)`
	margin-top: 16px;
`;

const Login = () => {
	const base = useConfig('base');
	const { setAuthStatus } = useAuthActions();
	const { enqueueSnackbar } = useSnackbar();

	const doLogin = async (credentials: Credentials) => {
		const success = await createSession(base, credentials);

		if (success) {
			setAuthStatus('loggedIn');
		} else {
			enqueueSnackbar(t`Log in failed`, { variant: 'error' });
		}

		return success;
	};

	return (
		<Container component="main" maxWidth="xs">
			<Paper>
				<Typography component="h1" variant="h5">
					{t`Log in`}
				</Typography>
				<Formik
					initialValues={{ username: '', password: '' }}
					onSubmit={(values) => doLogin(values)}
				>
					{({ isSubmitting }) => (
						<Form>
							<FormikTextField
								autoComplete="username"
								autoFocus
								label={t`User name`}
								name="username"
								required
								type="text"
								variant="outlined"
								testid="username"
							/>
							<FormikTextField
								autoComplete="current-password"
								label={t`Password`}
								name="password"
								required
								type="password"
								variant="outlined"
								testid="password"
							/>

							<LoginButton
								type="submit"
								disabled={isSubmitting}
								fullWidth
								icon="sign-in-alt"
							>
								{t`Log in`}
							</LoginButton>
						</Form>
					)}
				</Formik>
			</Paper>
		</Container>
	);
};

export default Login;
