import { isDefined } from '@persequor-com/common';

import {
	ComponentSchema,
	VocabularyDetailsExtensionButton,
	VocabularyDetailsExtensionSection,
	VocabularyDetailsExtension,
} from '../../types/ComponentSchema';

const getVocabularyDetailsExtensions = (
	components: ComponentSchema[],
	vocabulary: string,
): VocabularyDetailsExtension[] =>
	components
		.map((component) => component?.ui?.vocabularyDetails)
		.filter(isDefined)
		.reduce((acc, val) => acc.concat(val), []) //.flat()
		.filter((v) => vocabulary.includes(v.vocabularyName))
		.sort((a, b) => a.order - b.order);

const getButtons = (ext: VocabularyDetailsExtension[]) =>
	ext.filter(
		(x): x is VocabularyDetailsExtensionButton => x.type === 'button',
	);

const getSections = (ext: VocabularyDetailsExtension[]) =>
	ext.filter(
		(x): x is VocabularyDetailsExtensionSection => x.type === 'section',
	);

const guessEpcClass = (id?: string) =>
	id
		? id
				.replace('urn:epc:id:sgtin:', 'urn:epc:idpat:sgtin:')
				.replace(/\.([^.]*)$/, '.*')
		: undefined;

export {
	guessEpcClass,
	getButtons,
	getSections,
	getVocabularyDetailsExtensions,
};
