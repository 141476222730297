import React, { useEffect } from 'react';
import { produce } from 'immer';

import { useConfigActions } from './ConfigContext';

const Configure = ({
	fullScreen,
	children,
}: {
	fullScreen: boolean;
	children: React.ReactNode;
}) => {
	const { setLocalConfig } = useConfigActions();

	useEffect(() => {
		setLocalConfig(
			produce((draft) => {
				draft.disableMenus = fullScreen;
			}),
		);
	}, [fullScreen, setLocalConfig]);

	return <>{children}</>;
};

export default Configure;
