import React from 'react';
import { Block, Error, Loading, useQueryParams } from '@persequor-com/common';
import { useLocation } from 'react-router-dom';

import PageHeader from './PageHeader';
import { useFrontendSupportApi } from '../hooks/useSaga';
import { componentToBlock } from './builderFunctions';

type BuilderProps = {
	endpoint: string;
	id: string;
	path: string;
	render: RenderType;
	params?: Record<string, string>;
};

export type RenderType = 'normal' | 'dialog';

type Builder = {
	components?: import('./builderFunctions').BuildingBlocks[];
	title?: string;
};

const Builder = ({ path, endpoint, params, render }: BuilderProps) => {
	const location = useLocation();
	const queryParams = useQueryParams();
	const [data, loading, error] = useFrontendSupportApi<Builder>({
		endpoint,
		params: params ?? queryParams,
		timestamp: location.state?.timestamp,
	});

	if (loading) return <Loading />;
	if (error) return <Error>{error}</Error>;

	const blocks = data?.components?.map(componentToBlock(path, render));

	return (
		<>
			<PageHeader render={render}>{data?.title}</PageHeader>

			{blocks?.map((block, i) => (
				<Block key={i}>{block}</Block>
			))}
		</>
	);
};

export default Builder;
