import React from 'react';

import { SectionGrid } from './Section';
import Row from './Row';

type SkeletonRowsProps = {
	rows?: number;
};

const SkeletonRows = ({ rows = 3 }: SkeletonRowsProps) => (
	<SectionGrid>
		{[...Array(rows)].map((_, i) => (
			<Row key={i} loading />
		))}
	</SectionGrid>
);

export default SkeletonRows;
