import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@persequor-com/common';

type VocabularyIdentifierProps = {
	displayName?: string;
	icon?: import('@fortawesome/fontawesome-svg-core').IconName;
	id?: string;
	useId?: boolean;
};

const StyledIdentifier = styled.span`
	white-space: nowrap;
`;

const VocabularyIdentifier = ({
	displayName,
	icon,
	id,
	useId,
}: VocabularyIdentifierProps) => (
	<StyledIdentifier>
		{useId ? id : displayName ?? id} {icon ? <Icon name={icon} /> : null}
	</StyledIdentifier>
);

export default VocabularyIdentifier;
