import React from 'react';
import { Link } from '@persequor-com/common';

import { useShowEvent } from '../events/ShowEvent';

type ToggleEventProps = import('@persequor-com/common').WithChildren<
	import('@persequor-com/common').EventLink
>;

const ToggleEvent = ({ sagaEventId, children }: ToggleEventProps) => {
	const showEvent = useShowEvent();

	const handleClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
	) => {
		e.stopPropagation();
		showEvent(sagaEventId);
	};

	return <Link onClick={handleClick}>{children}</Link>;
};

export default ToggleEvent;
