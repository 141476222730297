import React, { lazy, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Container as MuiContainer, Typography } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Loading, t, hasPresentKey } from '@persequor-com/common';

import ErrorBoundary from './ErrorBoundary';
import { useRoutesState } from './RoutesContext';
import Configure from './config/Configure';
import { ShowEventProvider } from './components/events/ShowEvent';

const Container = styled(MuiContainer)`
	padding-top: 32px;
	padding-bottom: 32px;
`;

const Builder = lazy(() => import(`./builder/Builder`));

const NoMatch = () => {
	const location = useLocation();

	return (
		<>
			<Typography variant="h2">{t`Not found`}</Typography>
			<p>
				{t`No matching page found for path`}{' '}
				<code>{location.pathname}</code>
			</p>
		</>
	);
};

const Content = styled.main`
	flex-grow: 1;

	${(props) => props.theme.breakpoints.up('md')} {
		margin-left: ${(p) => p.theme.menu.width};
	}
`;

const Main = () => {
	const { routes, loading } = useRoutesState();

	// Wait for all routes to be loaded, otherwise a route might be re-rendered
	// causing the whole component to re-render.
	if (loading) return <Loading />;

	return (
		<Content>
			<Container maxWidth="lg">
				<ShowEventProvider>
					<Suspense fallback={<Loading />}>
						<Switch>
							{routes
								.filter(hasPresentKey('path'))
								.map((route) => (
									<Route key={route.id} path={route.path}>
										<ErrorBoundary>
											<Configure
												fullScreen={route.fullScreen}
											>
												{'builder' in route ? (
													<Builder
														id={route.id}
														path={route.path}
														endpoint={
															route.endpoint
														}
														render="normal"
													/>
												) : (
													<route.component
														id={route.id}
														path={route.path}
													/>
												)}
											</Configure>
										</ErrorBoundary>
									</Route>
								))}

							{/* TODO Empty placeholder */}
							<Route exact path="/">
								<Configure fullScreen={false}>
									<div />
								</Configure>
							</Route>

							<Route>
								<Configure fullScreen={false}>
									<NoMatch />
								</Configure>
							</Route>
						</Switch>
					</Suspense>
				</ShowEventProvider>
			</Container>
		</Content>
	);
};

export default Main;
