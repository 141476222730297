import React from 'react';
import {
	Collapse,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import styled from 'styled-components/macro';
import { Icon, useStickyState } from '@persequor-com/common';

type MenuSectionProps = {
	label: string;
	icon: import('@fortawesome/fontawesome-svg-core').IconName;
	children: React.ReactNode[];
};

const CategoryHeader = styled(ListItem)`
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: #f8f8f8;
` as typeof ListItem;

const MenuSection = ({ label, icon, children }: MenuSectionProps) => {
	const [isOpen, setOpen] = useStickyState(
		`MenuSection-${label}-open`,
		false,
	);

	const toggleOpen = () => setOpen((open) => !open);

	return (
		<>
			<CategoryHeader button onClick={() => toggleOpen()}>
				<ListItemIcon style={{ minWidth: 30 }}>
					<Icon name={icon} />
				</ListItemIcon>
				<ListItemText>{label}</ListItemText>
				<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
			</CategoryHeader>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				{children}
			</Collapse>
			<Divider />
		</>
	);
};

export default MenuSection;
