import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Loading, t } from '@persequor-com/common';

import Login from './Login';
import { useAuthState } from './AuthContext';

type AuthProps = {
	children: React.ReactNode;
};

const Auth = ({ children }: AuthProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const { authStatus } = useAuthState();

	useEffect(() => {
		if (authStatus === 'sessionExpired') {
			enqueueSnackbar(t`Your session has expired, please login`, {
				variant: 'warning',
			});
		}
	}, [authStatus, enqueueSnackbar]);

	if (authStatus === 'initialising') {
		return <Loading />;
	}

	if (authStatus === 'loggedOut' || authStatus === 'sessionExpired') {
		return <Login />;
	}

	return <>{children}</>;
};

export default Auth;
