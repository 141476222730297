import { setLocale } from 'yup';
import { t } from '@persequor-com/common';

const translateYup = () =>
	setLocale({
		// translator: array validation
		array: {
			min: ({ path, min }) =>
				t`${path} field must have at least ${min} items`,
			max: ({ path, max }) =>
				t`${path} field must have less than or equal to ${max} items`,
		},
		// translator: date validation
		date: {
			min: ({ path, min }) => t`${path} field must be later than ${min}`,
			max: ({ path, max }) =>
				t`${path} field must be at earlier than ${max}`,
		},
		// translator: mixed validation
		mixed: {
			default: ({ path }) => t`${path} is invalid`,
			required: ({ path }) => t`${path} is a required field`,
			oneOf: ({ path, values }) =>
				t`${path} must be one of the following values: ${values}`,
			notOneOf: ({ path, values }) =>
				t`${path} must not be one of the following values: ${values}`,
		},
		// translator: number validation
		number: {
			min: ({ path, min }) =>
				t`${path} must be greater than or equal to ${min}`,
			max: ({ path, max }) =>
				t`${path} must be less than or equal to ${max}`,
			lessThan: ({ path, less }) => t`${path} must be less than ${less}`,
			moreThan: ({ path, more }) =>
				t`${path} must be greater than ${more}`,
			positive: ({ path }) => t`${path} must be a positive number`,
			negative: ({ path }) => t`${path} must be a negative number`,
			integer: ({ path }) => t`${path} must be an integer`,
		},
		// translator: object validation
		object: {
			noUnknown: ({ path }) =>
				t`${path} field cannot have keys not specified in the object shape`,
		},
		// translator: string validation
		string: {
			length: ({ path, length }) =>
				t`${path} must be exactly ${length} characters`,
			min: ({ path, min }) =>
				t`${path} must be at least ${min} characters`,
			max: ({ path, max }) =>
				t`${path} must be at most ${max} characters`,
			matches: ({ path, regex }) =>
				t`${path} must match the following: "${regex}"`,
			email: ({ path }) => t`${path} must be a valid email`,
			url: ({ path }) => t`${path} must be a valid URL`,
			trim: ({ path }) => t`${path} must be a trimmed string`,
			lowercase: ({ path }) => t`${path} must be a lowercase string`,
			uppercase: ({ path }) => t`${path} must be a upper case string`,
		},
	});

export default translateYup;
