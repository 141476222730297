import { useEffect, useState } from 'react';
import { useFetch } from '@persequor-com/common';

import { useConfig } from '../config/ConfigContext';
import { useAuthActions } from '../auth/AuthContext';
import { buildRequestUrl } from './fetchFunctions';

type SagaRequestParams<T> = {
	endpoint: string;
	ready?: boolean;
	params?: {};
	options?: RequestInit;
	timestamp?: number;
	responseParser?: ResponseParser<T>;
	text?: T extends string ? boolean : never;
};

type SagaError = import('@persequor-com/common').SagaError;

type ResponseParser<T> = (res?: Response) => Promise<T | undefined>;

type SagaService = import('../config/configFunctions').SagaService;

const useSaga = <T>(
	{
		endpoint = '',
		ready = true,
		params = {},
		responseParser,
		text,
		...rest
	}: SagaRequestParams<T>,
	service: SagaService,
) => {
	const base = useConfig('base');
	const { checkSession } = useAuthActions();
	const [response, setResponse] = useState<T | undefined>(undefined);
	const [error, setError] = useState<SagaError | undefined>(undefined);
	const [processing, setProcessing] = useState(false);

	const url = ready ? buildRequestUrl(base, service, endpoint, params) : null;

	const [res, loading, err, forceUpdate] = useFetch({
		url,
		...rest,
	});

	useEffect(() => {
		const parse = async (res: Response): Promise<void> => {
			try {
				setProcessing(true);

				if (responseParser) {
					const parsedResponse = await responseParser(res);
					setError(undefined);
					setResponse(parsedResponse);
				} else if (res.status === 401) {
					setResponse(undefined);
					checkSession();
				} else if (!res.ok) {
					const sagaError = await jsonResponseParser<SagaError>(res);
					setError({ status: res.status, ...sagaError });
					setResponse(undefined);
					console.error(sagaError);
				} else {
					const parsedResponse = text
						? await textResponseParser<T>(res)
						: await jsonResponseParser<T>(res);
					setError(undefined);
					setResponse(parsedResponse);
				}
			} catch (error) {
				console.error(
					`Failed parsing response in useSaga: ${error.message}`,
				);
				setError({
					error: 'Error',
					exception: '',
					message: res.statusText,
					status: res.status,
					path: '',
					timestamp: new Date().toISOString(),
				});
				setResponse(undefined);
			} finally {
				setProcessing(false);
			}
		};

		if (res) {
			parse(res);
		} else if (err) {
			setError({
				error: `fetch ${err.name}`,
				exception: '',
				message: err.message,
				path: '',
				timestamp: new Date().toISOString(),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [res, err]);

	return [
		response,
		loading || processing,
		error,
		forceUpdate,
		setResponse,
	] as const;
};

const useEventApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'event');
const useExtensionApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'extension');
const useFrontendSupportApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'frontend-support');
const useMonitoringApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'monitoring');
const useSettingsApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'settings');
const useStatisticsApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'statistics');
const useSubscriptionApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'subscription');
const useVocabularyApi = <T = {}>(params: SagaRequestParams<T>) =>
	useSaga<T>(params, 'vocabulary');

// When `text` is set and textResponseParser is used, T will always be `string`
const textResponseParser = async <T>(res?: Response): Promise<T> =>
	(res?.text() as unknown) as T;

const jsonResponseParser = async <T>(res?: Response): Promise<T> => res?.json();

export {
	useEventApi,
	useExtensionApi,
	useFrontendSupportApi,
	useMonitoringApi,
	useSettingsApi,
	useStatisticsApi,
	useSubscriptionApi,
	useVocabularyApi,
};
