import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, addQueryParameters } from '@persequor-com/common';

import { useConfig } from '../../config/ConfigContext';
import ToggleEvent from './ToggleEvent';
import Dialog from './Dialog';
import VocabularyDetails from '../vocabulary-details/VocabularyDetails';
import PageBuilderLink from './PageBuilderLink';

type ExtensionLinkProps = import('@persequor-com/common').WithChildren<
	import('@persequor-com/common').EventExtensionData
>;

// Stop clicks propagating to row clicks in table
const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
	e.stopPropagation();

const ExtensionLink = ({ children, link, ...rest }: ExtensionLinkProps) => {
	const base = useConfig('base');

	if (!link) return <>{children}</>;

	switch (link.type) {
		case 'url': {
			const url = addQueryParameters(new URL(link.url), link.parameters);

			return (
				<a href={url} onClick={stopPropagation}>
					{children}
				</a>
			);
		}

		case 'api': {
			const url = addQueryParameters(
				`${base}/extension${link.url}`,
				link.parameters,
			);

			return (
				<a href={url} onClick={stopPropagation}>
					{children}
				</a>
			);
		}

		case 'coreLink': {
			return (
				<Link as={RouterLink} to={link.url} onClick={stopPropagation}>
					{children}
				</Link>
			);
		}

		case 'pageBuilder':
			return <PageBuilderLink {...link}>{children}</PageBuilderLink>;

		case 'vocabulary':
			if (rest.type === 'text') {
				return (
					<VocabularyDetails
						itemId={rest.text}
						vocabularyId={link.elementUri}
						vocabularyName={link.vocabularyUri}
					/>
				);
			} else {
				console.error(
					'Cell must be of type "text" when using vocabulary link',
				);
				return <>{children}</>;
			}

		case 'event':
			return <ToggleEvent {...link}>{children}</ToggleEvent>;

		case 'dialog':
			return <Dialog {...link}>{children}</Dialog>;

		default:
			return <>{children}</>;
	}
};

export default ExtensionLink;
